<template>


  <div>

        <TopBar :classes="'dashboard'" :columnnumber = "2" simple hidedropdown>
      <template v-slot:title>
        Verify account
      </template>
      <template v-slot:icon>
        <!-- <i class="fal fa-border-all align-self-center "></i> -->
      </template>
    </TopBar>

  <main class="verify-wrapper">

    <div v-if="mode=='password'">
      
    <p>Enter your password to continue</p>
    <div class=" col-md-6 col-sm-8 pl-0"><input type="password" class="hide"> 
      <input type="password" class="form-control" v-model="verifypassword"></div>
         <span class="text-danger" v-if="error=='catchwordempty'">Is empty</span>
      <span class="text-danger" v-if="error=='passwordinvalid'">Is invalid</span>
    </div>


    <div v-if="mode=='catchword'">

    <p>Type "Delete" to continue</p>
    <div class="col-md-6 col-sm-8  pl-0">
      <input type="text" class="form-control" v-model="verifycatchword"></div>
      <span class="text-danger" v-if="error=='catchwordempty'">Is empty</span>
      <span class="text-danger" v-if="error=='catchwordinvalid'">Is invalid</span>

    </div>


    <p class="mt-3"><a @click.prevent="verifyuser()" href="#" class="btn btn-primary">Proceed</a></p>
  </main>

</div>
</template>	
<style  lang="scss" scoped>
@import '@/scss/abstracts/variable.scss';
  @import'@/scss/abstracts/mixin.scss';
.verify-wrapper{
  margin:10px 0 0;
  width:  600px;
  padding: 0px 20px;
  margin-left: 140px; 
  &::v-deep{
    .custom-control-inline{
      margin-right:0px;
    }
  }
  
}
@include media-max-width($breakpoints-sm, $breakpoints-md){

  .verify-wrapper{
    height: 100%;
    margin:0px auto 80px;
    padding:0 20px;
    position: relative;
    width: 340px;
    
  }
}


</style>

<script type="text/javascript"></script>
<script>


    import Userapi from '@/services/api/users'

  import {TopBar} from '@/components/topbar'


	export default{
		data(){
			return{
          userid : null,
          users : [],
          pick :"",
          verifypassword : "",
          verifycatchword : "",
          confirmtoken : "",
          error : ""
        }
        },

        computed: {},
        filters: {},
        computed : {

          mode(){
            
            var toreturn = ""
            if( this.userinfo.signinlocal == '' && this.userinfo.signingoogle != '' ){
              toreturn = 'catchword'
            }else if( this.userinfo.signinlocal == 'email' ) {
              toreturn = 'password'
            }

            return toreturn
          },

          userinfo(){
            return this.$store.getters.user
          },

          token(){
            return localStorage.token
          }

        },
        mounted() {

          $('#preloader').addClass('hide')

        },
        methods: {

          verifyuser(){

            this.error = ""

            let self = this

            var action = this.$route.query.action

            Userapi.Verifyuser( { "t":this.token, "password":this.verifypassword, "catchword":this.verifycatchword, "mode":this.mode, "action":action } )
            .then( function(response){

            if( response.data.ok ){

              self.confirmtoken = response.data.confirmtoken
              self.$router.push({ name:response.data.route, query:{confirmtoken:self.confirmtoken} })

            }else{

              self.error = response.data.message

            }


            })
            .catch(error => console.log(error))
            .finally( () => {
            })

          }
        },
         components:{
    TopBar
  }
      }
</script>